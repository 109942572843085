body {
  padding-top: 3rem;
}

#header .navbar {
  border-bottom: 1rem solid #fff;
}

.container {
  max-width: 960px;
}

.site-header {
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.site-header a {
  color: #999;
  transition: ease-in-out color .15s;
  line-height: 80px;
}

.site-header a:hover {
  color: #fff;
  text-decoration: none;
}

.site-header i.fab {
  vertical-align: middle;
}

.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: #333;
  border-radius: 21px;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.product-device::before {
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  content: "";
  background-color: rgba(255, 255, 255, .1);
  border-radius: 5px;
}

.product-device-2 {
  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  background-color: #e5e5e5;
}

.border-top {
  border-top: 1px solid #e5e5e5;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.flex-equal > * {
  -ms-flex: 1;
  -webkit-box-flex: 1;
  flex: 1;
}

@media (min-width: 768px) {
  .flex-md-equal > * {
    -ms-flex: 1;
    -webkit-box-flex: 1;
    flex: 1;
  }
}

.overflow-hidden {
  overflow: hidden;
}

figure.image_container img {
  max-width: 100%;
  height: auto !important;
}

.ce_text.setlist {
  @media (min-width: 576px) {

  }
  @media (min-width: 768px) {
    column-count: 2;
    column-gap: 40px;
    column-rule-style: solid;
    column-rule-width: 1px;
    column-rule-color: lightblue;
    column-rule: 1px solid lightblue;
    column-width: 100px;
    column-fill: auto;
  }
  @media (min-width: 992px) {
    column-count: 3;
    column-gap: 40px;
    column-rule-style: solid;
    column-rule-width: 1px;
    column-rule-color: lightblue;
    column-rule: 1px solid lightblue;
    column-width: 100px;
    column-fill: auto;
  }
  @media (min-width: 1200px) {
    column-count: 4;
    column-gap: 40px;
    column-rule-style: solid;
    column-rule-width: 1px;
    column-rule-color: lightblue;
    column-rule: 1px solid lightblue;
    column-width: 100px;
    column-fill: auto;
  }
}

.back-to-top {
  width: 70px;
  padding-top: 8px;
  background: #fff;
  background: rgba(255, 255, 255, 0.20);
  position: fixed;
  bottom: 0;
  right: 20px;
  text-align: center;
  color: #000;
  font-size: 2em;
  border: 3px solid #000;
  border-bottom: none;
}

.bg-dark hr {
  border-color: #fff;
}

.carousel figure {
  margin-bottom: 0;
}

h2 > a {
  color: #fff;
}

h2 > a:hover {
  color: #fff;
}

iframe {
  width: 100% !important;
}